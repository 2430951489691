<template>
  <div
    :class="[{ 'display-none': !visible }]"
    class="notification"
  >
    <slot name="icon" v-bind="{ icon }">
      <i v-if="icon" :class="`notification__icon icon-${icon}`" />
    </slot>

    <div class="notification__content">
      <span v-if="title" class="notification__title">
        {{ title }}
      </span>
      <span v-if="message" class="notification__message">
        {{ message }}
      </span>
      <div
        v-if="action1 || action2"
        v-bind="{ action1, action2 }"
        class="notification__actions"
      >
        <slot name="action1" v-bind="{ action1, actionHandler }">
          <base-button
            v-if="action1"
            class="notification__action"
            @click="actionHandler(action1.onClick)"
          >
            {{ action1.text }}
          </base-button>
        </slot>

        <slot name="action1" v-bind="{ action2, actionHandler }">
          <base-button
            v-if="action2"
            class="notification__action"
            @click="actionHandler(action2.onClick)"
          >
            {{ action2.text }}
          </base-button>
        </slot>
      </div>
    </div>

    <slot name="close" v-bind="{ closeHandler }">
      <base-button
        aria-label="Close notification"
        class="notification__close"
        @click="closeHandler"
      >
        <i class="icon-close" />
      </base-button>
    </slot>
  </div>
</template>
<script>
import BaseButton from '~/components/BaseButton.vue'

export default {
  name: 'NotificationItem',
  components: {
    BaseButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String || Object,
      default: ''
    },
    message: {
      type: String || Object,
      default: ''
    },
    action1: {
      type: Object,
      default: null
    },
    action2: {
      type: Object,
      default: null
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'success',
      validator: function (value) {
        return ['danger', 'success', 'warning', 'info'].includes(value)
      }
    }
  },
  setup (_, ctx) {
    function closeHandler () {
      ctx.emit('close')
    }

    function actionHandler (action) {
      if (action !== null && typeof action === 'function') {
        action()
      }

      closeHandler()
    }

    return {
      closeHandler,
      actionHandler
    }
  }
}
</script>
<style lang="scss" scoped>
.notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 36px rgba(0, 0, 0, 0.08);
  background-color: var(--c-pink-1);
  color: var(--c-white);
  max-width: min(100vw, 400px);

  &__content {
    flex: 1;
  }

  &__icon {
    margin-right: 8px;
    --icon-font-size: 24px;

    @include for-mobile {
      margin-right: 8px;
    }

    @include for-mobile {
      align-self: flex-start;
    }

    &.icon-cart{
      &::before,
      &::after {
        color: white;
      }
    }
  }

  &__title {
    display: block;
    margin-right: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    @include for-mobile {
      margin-right: 12px;
    }
  }

  &__message {
    display: block;
    margin-right: 16px;

    @include for-mobile {
      margin-right: 12px;
    }
  }

  &__close {
    --button-top: 16px;
    --button-right: 16px;
    --button-padding: 0;
    --button-height: 0;

    --icon-font-size: 16px;
    --icon-color: var(--c-white);

    opacity: .5;

    &:hover {
      opacity: 1;
    }

    @include for-mobile {
      align-self: flex-start;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;

    @include for-mobile {
      margin-top: 12px;
    }
  }

  &__action {
    &:hover {
      text-decoration: underline;
    }

    &:nth-of-type(2) {
      margin-left: 24px;

      @include for-mobile {
        margin-left: 28px;
      }
    }
  }
}
</style>
